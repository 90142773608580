@import '../../../assets//styles/variables.scss';

.customer-tickets-container {
  height: 100%;
  display: flex;
  gap: 1rem;

  .tickets-menu {
    border-radius: 10px;
    background-color: white;
    width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    direction: ltr;

    @media (max-width: 767px) {
      width: 100%;
    }

    .top-container {
      display: flex;
      justify-content: space-between;
      gap: 2rem !important;
      margin: 1rem 1rem 0.5rem;

      .main-title {
        // border-bottom: 1px solid lightgrey;
        // margin: 0.5rem 1rem;
        // padding: 1rem 0.2rem;
      }
      .excel-button {
        margin: 0 !important;
        height: 40px;
        width: 40px;
      }
    }

    .ct-divider {
      border: 0;
      height: 1px;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    }

    > div {
      direction: rtl;

      .center-container {
        display: flex;
        justify-content: center;
      }

      .customer-tickets-filter-container {
        padding: 0.5rem 1rem;
        margin-bottom: 1rem 1rem 0;

        .input-label {
          margin-bottom: 0.4rem;
          display: block;
          font-size: 0.9rem;
        }

        .search-box {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;

          > div {
            flex: 1;

            &:first-child {
              margin-left: 0.5rem;
            }
          }

          .react-datepicker-wrapper {
            .react-datepicker__input-container {
              input {
                width: 100%;
                text-align: center;
                border: 1px solid #bebebe;
              }
            }
          }
        }

        .status-btns-group {
          direction: ltr;
          .button {
            background-color: white;
            color: black;
            border: 1px solid #bebebe;
            border-right: none;
            transition: background-color 500ms, color 500ms;

            &:hover {
              background-color: $gceBlue !important;
              color: white;
            }

            &:last-child {
              border-right: 1px solid #bebebe;
            }

            &.toggle.active {
              background-color: $gceDarkBlue !important;
            }
          }
        }

        .filters {
          margin: 0.8rem 0 0;

          .ui.styled.accordion {
            .title {
              user-select: none;
              i {
                transform: scaleX(-1);
              }

              &.active {
                i {
                  transform: scaleX(-1) rotate(90deg);
                }
              }
            }

            .content {
              padding: 0.8rem;
              .ui.form {
                display: flex;
                flex-direction: column;

                label {
                  &:not(:first-child) {
                    margin-top: 0.6rem;
                  }
                }

                input {
                  text-align: right;
                  padding: 0.67857143em 1em;
                }

                .filter-btn {
                  background-color: $gceBlue;
                  color: white;
                  margin: 0.8rem 0 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .ticket-details {
    border-radius: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      background-color: white;
    }
  }
}
