@import './../../../assets/styles/variables.scss';

.settings-list {
  margin: 1rem 2rem;

  .max-width-800 {
    max-width: 800px !important;
  }

  .settings-card-group {
    display: flex;
    margin: auto !important;
    max-width: 1000px;
    align-items: center;
    justify-content: center;

    .ui.cards > .card,
    .ui.card {
      border-radius: 5px;
      border: 1px solid $lighterGray;
      transition: all 0.3s ease-in-out;
      box-shadow: none;
      position: relative;
      z-index: 1;
      // background-image: linear-gradient(0deg, rgba(25, 120, 165, 1) 0%, rgba(3, 17, 99, 1) 100%);
      background-color: white;

      &::before {
        border-radius: 5px;
        background-image: linear-gradient(0deg, rgba(244, 244, 244, 1) 0%, rgba(202, 232, 255, 1) 100%);
        z-index: -1;
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
        border: 1px solid $lighterGray;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    .ui.cards a.card:hover,
    .ui.link.card:hover,
    .ui.link.cards .card:hover,
    a.ui.card:hover {
      border: 1px solid $lighterGray;
      border-radius: 5px;
      transform: translateY(-5px);
      box-shadow: 1px 9px 16px -1px rgba(0, 0, 0, 0.5);
      background-image: linear-gradient(0deg, rgba(244, 244, 244, 1) 0%, rgba(202, 232, 255, 1) 100%);
    }
  }

  .motion-card {
    margin: 1rem;
  }

  .settings-card {
    align-items: center;
    color: $gceDarkBlue;
    .card-header {
      font-size: 18px;
      text-align: center;
    }
    .card-icon {
      margin-top: 40px;
      font-size: 3.5rem;
      height: 70px;
    }
    .card-meta {
      padding: 10px;
      text-align: center;
    }
  }
}

.cards-page {
  margin: 2rem;

  .settings-card {
    height: 200px;
    border-radius: 10px;
    border: 1px solid white;
    background-color: white;
    color: $charcoalBlack;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;

    .card-text {
      margin: 0.5rem auto;
      font-size: 1.5rem;
    }
  }
}
