@import './../../../assets/styles/variables.scss';

.userForm {
  .checkbox-div {
    max-height: 200px;
    overflow: auto;
    border: 1px solid $lightererGray;
    border-radius: 10px;
    background-color: $lightererGray;
    padding: 0.5rem;

    .one-checkbox {
      margin: 0.2rem 0;
      font-size: 1.2rem;
    }
  }
}
