@import './variables.scss';
@import '~react-datepicker/dist/react-datepicker.css';
@font-face {
  font-family: Ar;
  src: url('./../fonts/AbdoMaster-Light.woff');
}

html {
  font-size: 14px;

  @media (max-width: 600px) {
    // font-size: 16px !important;
  }

  height: 100%;
  direction: rtl;
}

body {
  direction: rtl;
  margin: 0;
  font-family: 'Ar', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

p,
input,
button,
h1,
h2,
h3,
h4,
h5,
div,
textarea,
span,
button,
dropdown,
label {
  font-family: 'Ar', sans-serif !important;
}

.ui.table {
  text-align: right !important;
}

.red-icon {
  color: $red;
  cursor: pointer;
}
.blue-icon {
  color: $gceSkyBlue;
  cursor: pointer;
}

.ui.sortable.table thead th.sorted:after {
  margin-right: 1rem !important;
}

.ui.pagination.menu .item:last-child {
  border-radius: 0px !important;
  border-right-color: rgba(34, 36, 38, 0.15);
  border-right-style: solid;
  border-right-width: 1px;
}

.ui.form {
  .ui.selection.dropdown:focus,
  .ui.fluid.dropdown,
  .search-bar .ui.fluid.dropdown,
  .field > .selection.dropdown,
  textarea,
  .ui.selection.dropdown,
  input {
    background: $dropdownBg !important;
  }

  .field .ui.input input {
    height: 40px;
  }

  .ui.input > input,
  .search-bar .search-box .ui.icon.input > input {
    direction: rtl;
    text-align: right !important;
    padding: 0.35rem;
  }

  .ui.input.focus > input,
  .ui.input > input:focus,
  .ui.selection.active.dropdown:hover,
  .ui.selection.dropdown:focus {
    border-color: $lightGray !important;
    transition: ease-out 300ms;
  }
}

.ui.selection.dropdown .menu > .item {
  text-align: right;
}

.ui.celled.table tr td,
.ui.celled.table tr th {
  border-left: none !important;
}

.blue-button {
  background: $gceDarkBlue !important;
  color: white !important;
  transition: ease-out 250ms !important;
  .blue-buton-icon {
    background: rgba(0, 0, 0, 0.3) !important;
  }
  &:hover {
    background-color: $gceBlue !important;
  }
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.visible.transition {
  text-align: right !important;
}

.ui.modal > .actions {
  text-align: left !important;
}

.ui.checkbox .box,
.ui.checkbox label {
  padding-left: 0 !important;
  padding-right: 2rem !important;
}

.ui.checkbox .box:before,
.ui.checkbox label:before,
.ui.checkbox .box:after,
.ui.checkbox label:after {
  right: 0 !important;
}

.ui.fluid.dropdown {
  text-align: right !important;
}

.form-margin {
  .ui.form .field .ui.input input,
  .ui.form .fields .field .ui.input input {
    margin-bottom: 0.2rem !important;
  }
  .ui.form .field > label {
    margin-bottom: 0.5rem !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.ui.form input:not([type]):focus,
.ui.form input[type='date']:focus,
.ui.form input[type='datetime-local']:focus,
.ui.form input[type='email']:focus,
.ui.form input[type='file']:focus,
.ui.form input[type='number']:focus,
.ui.form input[type='password']:focus,
.ui.form input[type='search']:focus,
.ui.form input[type='tel']:focus,
.ui.form input[type='text']:focus,
.ui.form input[type='time']:focus,
.ui.form input[type='url']:focus,
.ui.form textarea:focus {
  border-color: gray !important;
}

.ui.menu .dropdown.item .menu {
  z-index: 100 !important;
}

.ui.search.dropdown > input.search {
  height: 30px;
}

.react-datepicker-popper[data-placement^='bottom'] {
  z-index: 10000 !important;
}

.image-preview-header {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.image-preview {
  border: 1px solid $lightereGray;
  border-radius: 10px;
  background-color: $lightererGray;
  padding: 1rem;
  .image-cropper {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: auto;
    border-radius: 10px;
    .img {
      border-radius: 10px;
      object-fit: contain;
    }
  }
}

.camera-div {
  margin: auto;
  text-align: center;
  .video-div {
    border-radius: 10px;
    width: 80%;
    margin: 1rem;
  }
  .ui.fluid.button {
    margin: auto;
  }
}

.height-50 {
  height: 50px;
}

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent;
}

.flex-1 {
  flex: 1;
}

.emoji-mart-category {
  .emoji-mart-emoji:hover {
    cursor: pointer;
    &:before {
      top: 4px !important;
      left: -4px !important;
    }
  }
}

.maintenance-form-img-preview {
  width: 100%;
  overflow: hidden;
  margin: auto;
  border-radius: 10px;
  .img {
    object-fit: contain;
  }
}

.loader-margin-top {
  margin-top: 10rem !important;
}

.ltr {
  direction: ltr;
}

.ui.label > .icon {
  width: auto;
  margin: 0 0 0 0.5em;
}

.excel-button {
  background: green !important;
  color: white !important;
  transition: ease-out 250ms !important;
  margin: 0.5rem auto !important;
  .excel-button-icon {
    background: rgba(0, 0, 0, 0.3) !important;
  }
  &:hover {
    background-color: rgb(79, 168, 79) !important;
  }
}

.ui.button {
  margin: 0;
}

.table-title-container {
  margin: 1rem 0.7rem 0rem 0.7rem;
}

.delete-ticket-errors-modal {
  font-weight: bold;
  .red-text {
    color: red;
    .icon {
      margin-left: 0.4rem !important;
    }
  }
}

.form-display {
  background: #fafafa;
  padding: 0.6rem;
  border-radius: 5px;
  border: 1px solid #e2e2e3;
}
