@import './../../assets//styles/variables.scss';

.sidebar-content {
  display: flex;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  .sidebar-page {
    .top-menu,
    .vertical-menu,
    .vertical-slim {
      .ui.menu {
        border: none !important;
        height: 100vh;
        background-color: $gceDarkBlue !important;
        border-radius: 0 !important;
      }
      .ui.labeled.icon.menu .item {
        color: white !important;
        transition: ease-in-out 300ms;
        padding: 1.5rem 1rem !important;
        min-width: 5rem;
        &:hover {
          background-color: rgba(0, 0, 0, 0.2) !important;
          // font-size: 1.2rem;
        }
      }

      .ui.vertical.menu {
        background: linear-gradient(#00295d, #00528d);
        .active.item {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    .borderless {
      border: none !important;

      .ui.tiny.image,
      .ui.tiny.images .image,
      .ui.tiny.images img,
      .ui.tiny.images svg {
        margin: auto !important;
      }
    }

    .ui.inverted.menu {
      direction: ltr;
    }

    .pushable > .pusher {
      background: $lightererGray;
    }
  }

  .page-contents-nav {
    width: 100%;
  }

  .page-contents {
    background-color: $lightererGray;
    height: calc(100vh - 62px);
    width: 100%;
    padding: 1rem;
    overflow-y: auto;
  }
}
