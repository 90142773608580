@import '../../../../assets/styles/variables.scss';

.ticket-img-wrppaer-fullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  cursor: pointer;

  .ticket-img-fullscreen {
    width: 85vw;
    height: 85vh;
    object-fit: contain;
  }
}

.system-ticket-content-wrapper {
  padding: 1.5rem 1rem;

  .title {
    font-size: 0.9rem;
    color: $gceMutedBlue;
    margin: 0 0 0.4rem 0;
  }

  .type-name,
  .content {
    margin: 0 0 1.5rem 0;
  }

  .content {
    white-space: pre-line;
  }

  .ticket-img-wrapper {
    position: relative;
    background-color: black;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    width: fit-content;

    .ticket-img {
      opacity: 0.7;
      height: 80px;
    }

    .icon {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      opacity: 0.9;
    }
  }
}
